import React from "react"
import { Link } from "gatsby"
import Products from "../../components/peaksuiteProducts"

const PeaksuiteEchoSection = ({ handleSubmit }) => (
  <>
    <div className="page-content pt-0" style={{ background: "#f8f8f8" }}>
      <section className="component pt-4 pb-0">
        <div className="container">
          <div className="row mb-5">
            <div className="col-md-12 peakSuite-product">
              <iframe
                src="https://drive.google.com/file/d/1qtNtcVneFVHxsz8zXd3752lvig4FS3SB/preview"
                frameBorder="0"
                width="60%"
                height="400"
                className="float-left mr-3 mb-3"
                style={{ boxShadow: "0 8px 10px rgba(0, 0, 0, 0.37)" }}
                title="echo"
              />
              <p>
                <span className="echo-color"> peakSUITE Echo </span>allows you
                to post news and keep your employees, contacts, and customers
                updated and interacting in a fun and simple way. With Echo, your
                Quickbase users can post Staffing and Company announcements,
                Client and Resource News, even Sales and Marketing Updates! Echo
                is such a powerful communication and motivational tool that our
                team at Quandary Consulting Group has been using (and perfecting
                its design) for years.
              </p>
              <p>
                With Echo fully refined, we’re ready to share it with your
                organization, giving you the benefits of streamlined
                communication, positive employee interaction, and well-informed
                customers.
              </p>
              <p>
                <span className="echo-color">
                  {" "}
                  Echo is the Twitter of Quickbase.
                </span>{" "}
                Post company news. Share sales and marketing updates. Motivate
                your team. All from a single portal without the background noise
                or clutter found in traditional business communication
                platforms.
                {/* Easily create posts in a user-friendly, attractive platform
                    without the background noise and clutter. You can even make
                    it the public landing page for your customers’ Quickbase
                    experience. */}
                {/* It’s simple to use. Choose an announcement type and fill out
                    the form with the update. Afterward, an administrator
                    approves the post for your audience using the custom
                    interface. With Echo integrated into your Quickbase
                    dashboard, restrict the roles to get the right information
                    to the right teams. Fast. */}
                Simply choose an announcement type and fill out the form. Once
                an administrator approves the post, Echo delivers the right
                message to the right people at the right time.
                {/* Mass emails get lost or ignored. Social media and team chats
                    are flooded with clutter that prevents your team from
                    staying informed. Reduce that noise with targeted messaging
                    to keep your staff in the know. */}
                Echo also lets users “like” announcements, set profile pictures,
                and perform other interactive features to keep your team
                engaged. It’s a frictionless way to keep everyone connected to
                your company informed from a single, custom interface on any
                Quickbase dashboard.
              </p>

              <div className="text-center">
                <Link
                  to="/contact/"
                  onClick={handleSubmit}
                  className="btn btn-primary btn-arrow echo-bg"
                >
                  Contact us
                </Link>
              </div>
            </div>
          </div>
          <div className="row peaksuite-products mt-5">
            <div className="col-lg-2 col-md-3 col-12 align-self-center  prod-order-2">
              {" "}
              <div className="return-btn-echo ">
                <Link
                  to="/peaksuite/"
                  className="btn btn-primary-outline px-3"
                  style={{
                    color: "#15678e",
                    border: "1px solid #15678e",
                    fontSize: "18px",
                  }}
                >
                  <i className="fa fa-caret-left mr-2" />
                  Return
                </Link>
              </div>
            </div>
            <div className="col-lg-10 col-md-9 col-12 prod-order-1">
              <Products />
            </div>
          </div>
        </div>
      </section>
    </div>
  </>
)

export default PeaksuiteEchoSection
